<template>
  <div>
    <b-card>
      <validation-observer
        ref="securityTab"
      >
        <p>{{ $t('referral-desc') }}</p>
        <hr>
        <b-form>
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('referral_id')"
                label-for="referral_id"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('referral_id')"
                  vid="referral_id"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="referral_id"
                      v-model="formData.referralId"
                      name="old-password"
                      :placeholder="$t('referral_id')"
                    />
                    <template #append>
                      <b-button
                        type="button"
                        style="height: 38px;gap: 3px;"
                        class="d-flex justify-center align-items-center mb-0 "
                        @click="copyRefCode()"
                      >
                        <BIconFileEarmarkFill class="mb-0" />
                        <span>{{ $t('copy') }}</span>
                      </b-button>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                v-if="!storeReferralPending"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="storeReferralId"
              >
                {{ $t('code_registration') }}
              </b-button>

              <b-button
                v-if="storeReferralPending"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      class=""
    >
      <b-row align-h="center">
        <b-col cols="12">
          <b-table
            responsive
            striped
            hover
            sticky-header="1000px"
            :items="items"
            :fields="computedFields"
          >
            <template #cell(created_at)="data">
              <span>
                {{ moment(data.item.date).format('YYYY-MM-DD') }}
              </span>
            </template>
          </b-table>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="paginate.total > paginate.per_page"
            v-model="paginate.page"
            :total-rows="paginate.total"
            :per-page="paginate.per_page"
            align="fill"
            size="sm"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BTable, BPagination,
  BInputGroup, BIconFileEarmarkFill,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'
import moment from '@/libs/moment'

const profile = new ProfileApis()
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BTable,
    BPagination,
    BIconFileEarmarkFill,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      formData: {
        referralId: '',
      },
      moment,
      storeReferralPending: false,
      items: [],
      paginate: {
        page: 1,
        per_page: 10,
        total: 0,
      },
    }
  },
  computed: {
    computedFields() {
      return this.fields
    },
    page() {
      return this.paginate.page
    },
    fields() {
      return [
        { key: 'first_name', label: this.$t('name') },
        { key: 'last_name', label: this.$t('last_name') },
        { key: 'created_at', label: this.$t('date-registration') },
      ]
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.getReferralId()
      }
    },
  },
  created() {
    this.getReferralId()
  },

  methods: {
    copyRefCode() {
      navigator.clipboard.writeText(`${process.env.VUE_APP_APP_URL}/${this.$route.params.lang}/register?refID=${this.formData.referralId}`)
      this.$swal({
        title: this.$t('referral-code-copied'),
        icon: 'success',
        timer: 2500,
        showConfirmButton: false,
      })
    },
    getReferralId() {
      profile.getReferralId(this.paginate).then(res => {
        this.formData.referralId = res.data.results.code
        this.items = res.data.results.users
        this.paginate.total = res.data.results.paginate.total
      })
    },
    storeReferralId() {
      this.storeReferralPending = true
      profile.storeReferralId({ code: this.formData.referralId }).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.storeReferralPending = false
      })
    },
  },

}
</script>
